<template>
    <div class="col-12 col-lg-6 g c">
        <div class="card card-body" v-if="current.name">
            <div class="media">
                <a class="align-self-center d-lg-flex d-none" href="javascript:;">
                    <img v-if="!settings.calls_image || !current.image" :src="require('@/assets/images/bell.gif')" style="width: 200px; border-radius: 50%">
                    <img v-if="settings.calls_image && current.image" :src="current.image" style="width: 200px; height: 200px; border-radius: 10px">
                </a>
                <div class="media-body align-self-center" style="padding-right: 40px">
                    <h1>{{ current.name }}</h1>
                    <h4>{{ current.classroom }}</h4>
                </div>
            </div>
        </div>
        <div class=" table-responsive card" style="min-height: auto !important">
            <table class="table table-bordered table-hover">
                <thead>
                    <th style="background: #fff !important">
                        طالب
                    </th>
                    <th style="background: #fff !important">
                        الفصل
                    </th>
                    <th style="background: #fff !important">
                        التوقيت
                    </th>
                </thead>
                <tbody>
                    <tr v-for="c in calls" :key="c._id">
                        <td>
                            {{ c.name }}
                        </td>
                        <td>
                            {{ c.classroom }}
                        </td>
                        <td>
                            {{ c.date.split(" ")[1] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            current: {},
            calls: [],
            interval: null,
            last_name: null,
            settings: {},
            key: "kvfbSITh"
        }
    },
    beforeDestroy(){
        if(this.interval){
            clearInterval(this.interval);
        }
    },
    created(){
        if(!checkPer("calls")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.fetchCalls();
        g.interval = setInterval(() => {
            g.fetchCalls()
        }, 5000);
        $.post(api + '/user/calls/settings', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.settings = r.response
                if(g.settings.key){
                    g.key = g.settings.key
                }
            }
        }).fail(function(){
            alert("حدث خطأ في الاتصال", 200)
        })
    },
    methods: {
        playSound(){
            var audio = new Audio('/assets/sounds/bell.mp3');
            audio.play();
            var g = this;
            setTimeout(function(){
                if(g.settings.calls_voice){
                    var audio = new Audio('https://texttospeech.responsivevoice.org/v1/text:synthesize?text='+encodeURI(g.current.name)+'&lang=ar&engine=g3&name=&pitch=0.5&rate=0.5&volume=1&key='+g.key+'&gender=' + g.settings.calls_voice_gender);
                    audio.play();
                }
            }, 500)
        },
        fetchCalls(){
            var g = this;
            g.current = {}
            $.post(api + '/user/calls/history', {
                jwt: this.user.jwt
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    if(r.response.next){
                        g.current = r.response.next
                        g.playSound()
                    }
                    g.calls = r.response.calls
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        }
    }
}
</script>

<style>

</style>